import React from "react";

function App() {
  return (
    <section className="vCard">
      <section className="info">
        <h1>Tom Wiesseckel</h1>
      </section>
      <section className="image">
        <span>image by @robsblog</span>
      </section>
    </section>
  );
}

export default App;
